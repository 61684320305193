import consumer from "./consumer"
export const TREE_CHANNEL_ACTIONS = {
  ai_complete_drivers: 'ai_complete_drivers',
  copy_drivers: 'copy_drivers',
  reorder_drivers: 'reorder_drivers',
  gpt_drivers_added: 'gpt_drivers_added',
  edit_driver_details: 'edit_driver_details',
  edit_driver_answer: 'edit_driver_answer',
  edit_decision_input: 'edit_decision_input',
  edit_recommendation_input: 'edit_recommendation_input',
  driver_state_changed: 'driver_state_changed'
}
class TreeChannel {
  constructor(objectSlug) {
    this.decisionSlug = objectSlug;
  }
  initSubscription() {
    return consumer.subscriptions.create({
      channel: "TreeChannel",
      decision_slug: this.decisionSlug
    }, {
      connected() {
        // Called when the subscription is ready for use on the server
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        // Called when there's incoming data on the websocket for this channel
      }
    })
  }
  static initInstance(objectSlug) {
    this._instance = new TreeChannel(objectSlug);
    return this._instance;
  }

  static get instance() {
    return this._instance;
  }
}
export default TreeChannel;
